import { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import PostApiCall from './Components/Helpers/PostAPI';
import Footer from './Components/OnlineOrdering/Footer/Footer';
import Home from './Components/OnlineOrdering/Home/Home';
import NavbarMenu from './Components/OnlineOrdering/Navbar/Navbar';
import CancellationRefundPolicyPage from './Pages/CancellationAndReturnPolicy';
import DisclaimerPage from './Pages/Disclaimer';
import PrivacyPage from './Pages/Privacy';
import ShippingDeliveryTermsPage from './Pages/ShippingAndDeliveryTerms';
import DesignTowTermsConditionPage from './Pages/TermsAndConditions';
import { store } from './Store/Store';

function App() {
  const { setClientDetails, setRestaurantOpen, setFssai,
    setRestaurantAddress, setRestaurantTime, setRestaurantName, setCurrencyCode,
    setCustomerValidation, setClientLogo, setClientCredentials } = useContext(store)
  useEffect(() => {
    PostApiCall.postRequest(
      {
        id: "",
      },
      "getClientData"
    ).then((results) =>
      results?.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          setClientDetails(obj1.message);
          setClientCredentials(obj1.message1);
          setRestaurantOpen(obj1.message[0]?.restaurant_open);
          setFssai(obj1.message[0].fld_fssai_number);
          setRestaurantAddress(
            obj1.message[0].fld_address +
            ", " +
            obj1.message[0].city_name +
            ", " +
            obj1.message[0].state_name
          );
          setRestaurantTime(obj1.message[0].res_timings);
          setRestaurantName(obj1.message[0].fld_restaurantname);
          setCurrencyCode(obj1.message[0].fld_currency);
          setCustomerValidation(obj1.message[0].fld_customer_validation_using);
          setClientLogo(obj1.message[0]?.fld_image);
        }
      })
    );
  }, [])
  return (
    <div className="App">
      <Router>
        <NavbarMenu />
        <Routes>
          <Route exact path="/" element={<Home />}></Route>
          <Route exact path="/privacy-policy" element={<PrivacyPage />}></Route>
          <Route exact path="/terms-condition" element={<DesignTowTermsConditionPage />}></Route>
          <Route exact path="/cancellation-refund-policy" element={<CancellationRefundPolicyPage />}></Route>
          <Route exact path="/delivery-terms" element={<ShippingDeliveryTermsPage />}></Route>
          <Route exact path="/disclaimer" element={<DisclaimerPage />}></Route>
        </Routes>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
