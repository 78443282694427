export default function PrivacyPage() {
    return (
        <>
            <div className="design-two pt-4">
                <div className="page-header terms-page">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="page-header-box">
                                    <h1 className="text-anime">Privacy Policy</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <b>Welcome to Arihafoods!</b>
                                <p>
                                    We understand that privacy is important to you! This website
                                    belongs to Arihafoods. This Privacy Policy explains how
                                    we collect, use, and share information you give us on this site.
                                    Please read it carefully before sharing any information.
                                </p>
                                <b>Agreement:</b>
                                <p>
                                    By using this site, you agree to this Privacy Policy. When you
                                    provide information through this site, you agree to how we
                                    handle it as explained here.
                                </p>
                                <b>What Information We Collect:</b>
                                <p>
                                    You can visit our site without telling us who you are. When you
                                    do visit, our servers log some details, like which pages you
                                    looked at.
                                </p>
                                <p>
                                    We might ask for your name, address, phone number, email, or
                                    other info when you:
                                </p>
                                <ul>
                                    <li>Order online</li>
                                    <li>Enter a contest</li>
                                    <li>Take a survey</li>
                                    <li>Sign up for emails</li>
                                    <li>Apply for a job</li>
                                    <li>Ask about franchising</li>
                                    <li>Give us feedback</li>
                                </ul>
                                <b>We use your info to:</b>
                                <ul>
                                    <li>Improve our website</li>
                                    <li>Personalize your experience</li>
                                    <li>Communicate with you</li>
                                    <li>Process orders</li>
                                    <li>Measure website traffic</li>
                                    <li>Give you prizes or rewards</li>
                                    <li>Respond to your requests</li>
                                    <li>Tell you about changes</li>
                                    <li>Do marketing research</li>
                                    <li>Send you offers or info we think you'll like</li>
                                </ul>
                                <b>Credit Card Details:</b>
                                <p>We don't keep your credit card info.</p>
                                <b>Sharing Information:</b>
                                <p>We might share info with:</p>
                                <ul>
                                    <li>Our partners who help us run things</li>
                                    <li>Other companies related to us</li>
                                    <li>If you say it's okay</li>
                                    <li>If we're required by law</li>
                                    <li>If it's part of a joint promotion</li>
                                    <li>
                                        If we think you'd want to know about something from another
                                        company
                                    </li>
                                </ul>
                                <b>Access, Accuracy, and Security:</b>
                                <p>
                                    We try to keep your info accurate and safe. You can ask to see
                                    or fix your info anytime.
                                </p>
                                <b>Changes to This Policy:</b>
                                <p>
                                    If we change this policy, we'll put the new one on our site.
                                    Check back now and then, especially before you give us any
                                    personal info.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
