import React, { useContext } from "react";
import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa";
import { store } from "../../../Store/Store";
import "./Footer.css";
function Footer() {
    const {
        fssai,
        setFssai,
        restaurantName,
        cardAccepted,
        setcardAccepted,
        clientDetails,
    } = useContext(store);
    return (
        <>
            <div className="footer-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 mt-4">
                            <div className="row text-center text-lg-start justify-content-center align-items-lg-end justify-content-lg-between">
                                <div className="col-12">
                                    {/* <img src={clientDetails[0]?.fld_image} className="img-fluid footer-logo" />
                                    <p className="text-white">
                                        The Home of Authentic Indian Cuisine
                                    </p> */}
                                    <div className="footer-social-links d-lg-flex">
                                        <div>
                                            <p className="text-white fs-5 mb-0">Follow Us</p>
                                            <ul>
                                                {clientDetails[0]?.fld_facebook && (
                                                    <li>
                                                        <a
                                                            href={clientDetails[0]?.fld_facebook}
                                                            target="_blank">
                                                            <FaFacebookF className="social-icon" />
                                                        </a>
                                                    </li>
                                                )}
                                                {clientDetails[0]?.fld_instagram && (
                                                    <li>
                                                        <a
                                                            href={clientDetails[0]?.fld_instagram}
                                                            target="_blank">
                                                            <FaInstagram className="social-icon" />
                                                        </a>
                                                    </li>
                                                )}
                                                {clientDetails[0]?.fld_youtube && (
                                                    <li>
                                                        <a
                                                            href={clientDetails[0]?.fld_youtube}
                                                            target="_blank">
                                                            <FaYoutube className="social-icon" />
                                                        </a>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                        <div className="ms-lg-4">
                                            <p className="text-white fs-5 mb-0">Call Us</p>
                                            <a
                                                href={`tel:${clientDetails[0]?.fld_phonenumber}`}
                                                target="_blank"
                                                className="text-white footer-adress-fs ">
                                                {clientDetails[0]?.fld_phonenumber}
                                            </a>
                                        </div>
                                        <div className="ms-lg-4">
                                            <p className="text-white fs-5 mb-0">Locate Us</p>
                                            <a
                                                href={`https://maps.app.goo.gl/GnUigKm8PYa46Nky7`}
                                                target="_blank"
                                                className="text-white footer-adress-fs">
                                                {clientDetails[0]?.fld_address}
                                            </a>
                                        </div>
                                        {/* <div className="ms-lg-4 text-white">
                                            <img src={fssaiIcon} className="img-fluid fssai-icon mb-0" />
                                            <p>{clientDetails[0]?.fld_fssai_number}</p>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="col-lg-3 mt-4 mt-lg-0">
                                    <div className="info-box">
                                        {/* <div className="icon">
                                            <img src={Timing} alt="" />
                                        </div> */}
                                        {/* <p className="text-white text-center">Monday - Sunday</p>
                                        <p className="text-white text-center">
                                            11:45 am - 01:45 am */}
                                        {/* {restaurantTime} */}
                                        {/* </p> */}
                                    </div>
                                </div>
                                {/* <div className="col-lg-3 col-6">
                  <div className="footer-social-links text-center">
                    <ul>
                      <li>
                        <a href={clientDetails[0]?.fld_facebook} target="_blank">

                          <FaFacebookF className="social-icon" />

                        </a>
                      </li>
                      <li>
                        <a href={clientDetails[0]?.fld_instagram} target="_blank">

                          <FaInstagram className="social-icon" />

                        </a>
                      </li>
                    </ul>
                    <p className="text-white text-center">
                      Let's Connect Socially
                    </p>
                  </div>
                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="row copyright-text">
                        <div className="col-l2 mb-2 text-center">
                            <ul className="footer-nav w-100 text-center text-lg-start justify-content-center">
                                <li>
                                    <a href="/terms-condition">Terms and Conditions</a>
                                </li>
                                <li>
                                    <a href="/privacy-policy">Privacy Policy</a>
                                </li>
                                <li>
                                    <a href="/cancellation-refund-policy">Cancellation and Refund Policy</a>
                                </li>
                                <li>
                                    <a href="/delivery-terms">Delivery Terms</a>
                                </li>
                                {/* <li>
                  <a href="">FAQ</a>
                </li> */}
                                <li>
                                    <a href="/disclaimer">Disclaimer</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-12 text-center">
                            Copyright © 2024 {clientDetails[0]?.fld_companyname}
                            {/* {clientDetails[0]?.fld_restaurantname} */}
                            <br className="d-block d-md-none" /> All Rights Reserved.
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid footer-bottom-company-name mb-lg-0 pb-4 pb-md-0">
                {/* <div className="container"> */}
                <div className="row">
                    <div className="col-12 col-lg-6 text-lg-start text-center text-dark pt-2 pb-1 py-lg-3">
                        Powered by Grub Digest - <br className="d-block d-md-none" /> A
                        Product of
                        <a
                            className="text-dark"
                            href="https://globaltrendz.com/"
                            target="blank">
                            &nbsp; Global Trendz
                        </a>
                    </div>
                    {/* <div className="col-12 col-md-6 text-md-end text-center text-white py-1 py-lg-3">
              <a
                className="text-white"
                href="https://grubdigest.com/"
                target="blank">
                {" "}
                Powered By Grub Digest
              </a>
            </div> */}
                </div>
                {/* </div> */}
            </div>
        </>
    );
}
export default Footer;
