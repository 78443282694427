import moment from "moment";
import Notiflix from "notiflix";
import React, { useContext, useState } from "react";
import { Button, Card, Col, Container, Offcanvas, Row } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";

import NonVegetarian from "../../../assets/img/nonveg_icon.png";
import Vegan from "../../../assets/img/vegan_icon.png";
import Vegetarian from "../../../assets/img/veg_icon.png";
import PostApiCall from "../../OnlineOrdering/helper/PostAPI";


import { store } from "../../../Store/Store";


const Config = () => {
  const { showConfigSlider, setShowConfigSlider } = useContext(store);
  const { itemId } = useContext(store);
  const [spiceLevel] = useState(null)

  const handleClose = () => {
    setShowConfigSlider(false);
    settotalAmount(0.00);
    setdiscountAmount(0.00);

  };
  //add property
  const { property } = useContext(store);
  const { propertyList } = useContext(store);

  const { addOnGroup } = useContext(store);
  const { addOnList } = useContext(store);

  const { selectedProperties, setSelectedProperties } = useContext(store);
  // discount
  const [setdiscountAmount] = useState(null);
  // total
  const [totalAmount, settotalAmount] = useState(0.00);

  // add on price update
  const { selectedAddons, setSelectedAddons } = useContext(store);

  const { cartData, setCartData } = useContext(store);

  const { setCartTotal } = useContext(store);
  const { setCartItems } = useContext(store);
  const [setcuisines] = useState([]);
  const [setallcuisines] = useState([]);
  const [setSelectSpiceModal] = useState(false);
  const { itemPrice } = useContext(store);

  const getCart = async () => {
    var login = localStorage.getItem("LoginDetailsWeb");
    var logindetails = JSON.parse(login)[0];

    var Order = localStorage.getItem("OrderData");
    var Orderdetails = JSON.parse(Order);

    PostApiCall.postRequest(
      {
        orderid:
          localStorage.getItem("OrderData") == null
            ? null
            : Orderdetails.order_id,
        id:
          localStorage.getItem("LoginDetailsWeb") == null
            ? null
            : logindetails.CustomerId,
        stateid: null,
      },
      "getCartWebsite"
    ).then((result) =>
      result?.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          if (obj.message.length == 0) {
            localStorage.removeItem("OrderData");
          } else {
            setCartData(obj.message);
            setdiscountAmount(obj.message[0].fld_cart_discount_amount);
            settotalAmount(obj.message[0].fld_cart_amount_no_shipping);
            setCartTotal(obj.message[0].fld_total_net_amount.toFixed(2))
            setCartItems(obj.message[0].item_count)

          }
          setCartData(obj.message);

          // getMenuList();
        }
      })
    );
  };
  const getMenuData = (message) => {
    setcuisines(message);
    setallcuisines(message);
    setSelectSpiceModal(false);
  }
  const getMenuList = () => {
    var Order =
      localStorage.getItem("OrderData");
    var Orderdetails = JSON.parse(Order);
    PostApiCall.postRequest(
      {
        categoryid: "1",
        orderid: localStorage.getItem("OrderData") ==
          null
          ? 0
          : Orderdetails.order_id
      },
      "getFoodMenuOrder"
    ).then((results) =>
      results?.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          setcuisines(obj1.message);
          setallcuisines(obj1.message);
          setSelectSpiceModal(false);
          //localStorage.setItem("BannerDetails", JSON.stringify(obj1.message))
          Notiflix.Loading.Remove();
        }
      }))
  }
  return (
    <Container fluid>
      <Offcanvas show={showConfigSlider} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Customise</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {property == null
            ? "" : <h5>Property</h5>}
          <Accordion defaultActiveKey={["0", "1", "2"]} alwaysOpen>
            {property == null
              ? ""
              : property.map((data, i) => {
                return (
                  <Accordion.Item eventKey={i}>
                    <Accordion.Header>
                      {data.FLD_Property_Name}
                    </Accordion.Header>
                    <Accordion.Body>
                      {propertyList == null
                        ? ""
                        : propertyList
                          .filter(
                            (item) =>
                              item.FLD_Property_Name ==
                              data.FLD_Property_Name
                          )
                          .map((propertyList) => {
                            return (
                              <Row>
                                <Col lg={12}>
                                  <Card className="mb-lg-0 mb-0">
                                    <Card.Body className="px-0 py-lg-2 py-2">
                                      <Form>
                                        <div className="d-flex justify-content-between align-items-center gap-2">
                                          <div className="d-flex gap-2">
                                            <Form.Check
                                              value={propertyList.FLD_ID}
                                              onChange={(e) => {
                                                let newProperty =
                                                  selectedProperties;

                                                if (e.target.checked) {
                                                  newProperty.push(
                                                    e.target.value
                                                  );
                                                  setSelectedProperties(
                                                    newProperty
                                                  );

                                                  settotalAmount(
                                                    (
                                                      Number(
                                                        totalAmount
                                                      ) +
                                                      Number(
                                                        propertyList.FLD_Price
                                                      )
                                                    ).toFixed(2)
                                                  );
                                                } else {
                                                  if (
                                                    newProperty.includes(
                                                      e.target.value
                                                    )
                                                  ) {
                                                    let indexProperty =
                                                      newProperty.indexOf(
                                                        e.target.value
                                                      );
                                                    newProperty.splice(
                                                      indexProperty,
                                                      1
                                                    );
                                                    setSelectedProperties(
                                                      newProperty.splice(
                                                        indexProperty,
                                                        1
                                                      )
                                                    );
                                                    if (
                                                      totalAmount > 0.0
                                                    ) {
                                                      settotalAmount(
                                                        (
                                                          Number(
                                                            totalAmount
                                                          ) -
                                                          Number(
                                                            propertyList.FLD_Price
                                                          )
                                                        ).toFixed(2)
                                                      );
                                                    }
                                                  }
                                                }
                                              }}
                                            />
                                            <h6 className="mb-0">
                                              {" "}
                                              {
                                                propertyList.FLD_Property_value
                                              }
                                            </h6>
                                          </div>
                                          <div>
                                            {Number(
                                              propertyList.FLD_Price
                                            ).toFixed(2)}
                                          </div>
                                        </div>
                                      </Form>
                                    </Card.Body>
                                  </Card>
                                </Col>
                              </Row>
                            );
                          })}
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}
          </Accordion>
          {addOnGroup == null
            ? "" : <h5>Add-Ons</h5>}
          <Accordion defaultActiveKey={["0", "1", "2"]} alwaysOpen>
            {addOnGroup == null
              ? ""
              : addOnGroup.map((data, i) => {
                return (
                  <Accordion.Item eventKey={i}>
                    <Accordion.Header>
                      {data.FLD_Addon_Group}
                    </Accordion.Header>
                    <Accordion.Body>
                      {addOnList == null
                        ? ""
                        :
                        addOnList
                          .filter(
                            (item) =>
                              item.FLD_Addon_Group == data.FLD_Addon_Group
                          )
                          .map((addOnList) => {
                            return (
                              <Row>
                                <Col lg={12}>
                                  <Card className="mb-lg-0 mb-0">
                                    <Card.Body className="px-0 py-lg-2 py-2">
                                      <Form>
                                        <div className="d-flex justify-content-between align-items-center gap-2">
                                          <div className="d-flex gap-2">
                                            <Form.Check
                                              value={addOnList.FLD_ID}
                                              onChange={(e) => {
                                                let newAddon =
                                                  selectedAddons;
                                                if (e.target.checked) {
                                                  newAddon.push(
                                                    e.target.value
                                                  );
                                                  settotalAmount(
                                                    (
                                                      Number(
                                                        totalAmount
                                                      ) +
                                                      Number(
                                                        addOnList.FLD_Amount
                                                      )
                                                    ).toFixed(2)
                                                  );
                                                } else {
                                                  if (
                                                    newAddon.includes(
                                                      e.target.value
                                                    )
                                                  ) {
                                                    var index =
                                                      newAddon.indexOf(
                                                        e.target.value
                                                      );
                                                    newAddon.splice(
                                                      index,
                                                      1
                                                    );
                                                    if (
                                                      totalAmount > 0.0
                                                    ) {
                                                      settotalAmount(
                                                        (
                                                          Number(
                                                            totalAmount
                                                          ) -
                                                          Number(
                                                            addOnList.FLD_Amount
                                                          )
                                                        ).toFixed(2)
                                                      );
                                                    }
                                                  }
                                                }
                                                setSelectedAddons(
                                                  newAddon
                                                );
                                              }}
                                            />
                                            <h6 className="mb-0">
                                              <img
                                                src={
                                                  addOnList.FLD_Food_type ==
                                                    "Veg"
                                                    ? Vegetarian
                                                    : addOnList.FLD_Food_type ==
                                                      "Nonveg"
                                                      ? NonVegetarian
                                                      : Vegan
                                                }
                                                className="recipe-type"
                                              />{" "}
                                              {addOnList.FLD_Addon_Name}
                                            </h6>
                                          </div>
                                          <div>
                                            {Number(
                                              addOnList.FLD_Amount
                                            ).toFixed(2)}
                                          </div>
                                        </div>
                                      </Form>
                                    </Card.Body>
                                  </Card>
                                </Col>
                              </Row>
                            );
                          })}
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}
          </Accordion>
          <Row>
            <Col
              lg={12}
              className="d-flex justify-content-center py-lg-0 py-3 mt-3 bg-white"
            >
              <Button
                variant="danger"
                className="w-100 text-center mx-auto"
                size="lg"
                onClick={() => {
                  handleClose();
                  var login = localStorage.getItem("LoginDetailsWeb");
                  var logindetails = JSON.parse(login)[0];
                  PostApiCall.postRequest(
                    {
                      orderdate: moment().format("lll"),
                      itemdetails: `[{"Item":${itemId},"Quantity":${1},"SpiceLevel":${spiceLevel},"specialInstruction":"","addons":"${selectedAddons}","property":"${selectedProperties}","copyconfig":"No"}]`,
                      status: "INCART",
                      customerid:
                        localStorage.getItem("LoginDetailsWeb") == null
                          ? null
                          : logindetails.CustomerId,
                      createdon: moment().format("lll"),
                      updatedon: moment().format("lll"),
                      orderid:
                        localStorage.getItem("OrderData") == null
                          ? 0
                          : JSON.parse(localStorage.getItem("OrderData"))
                            .order_id,
                      updatedby:
                        localStorage.getItem("LoginDetailsWeb") == null
                          ? null
                          : logindetails.CustomerId,

                      stateid: null,
                    },
                    "addToCart"
                  ).then((result) =>
                    result?.json().then((obj) => {
                      if (result.status == 200 || result.status == 201) {
                        localStorage.setItem(
                          "OrderData",
                          JSON.stringify(obj.message[0])
                        );
                        setSelectedAddons([]);
                        setSelectedProperties([])
                      } else {
                        Notiflix.Notify.Failure(obj.message);
                      }
                      getMenuData();
                      getCart();
                    })
                  );
                }}
              >
                <div>Add to cart
                  {Number(
                    totalAmount
                  ) +
                    Number(
                      itemPrice
                    ) == 0.0 ? "" : (Number(
                      totalAmount
                    ) +
                      Number(
                        itemPrice
                      )).toFixed(2)}
                </div>
              </Button>
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
    </Container>
  );
}

export default Config;