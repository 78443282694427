export default function DesignTowTermsConditionPage() {
    return (
        <>
            <div className="design-two pt-4">
                <div className="page-header terms-page">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="page-header-box">
                                    <h1 className="text-anime">Terms and Conditions</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <b>1. Acceptance of Terms</b>
                                <p>
                                    By accessing or using the services provided by Arihafoods
                                    , you agree to be bound by these Terms and
                                    Conditions. If you do not agree with any part of these terms,
                                    you may not access the services.
                                </p>
                                <b>2. Service Description</b>
                                <p>
                                    Arihafoods provides dining services,
                                    including food and beverage orders, catering, and event hosting,
                                    either through our physical location or through our online
                                    platforms.
                                </p>
                                <b>3. User Responsibilities</b>
                                <p>
                                    You agree to use the services provided by Arihafoods
                                    for lawful purposes only. You shall not engage in any
                                    conduct that violates any applicable laws or regulations.
                                </p>
                                <b>4. Orders and Payments</b>
                                <p>
                                    By placing an order with Arihafoods, you
                                    agree to pay all charges associated with the order, including
                                    the cost of food, beverages, taxes, and any applicable delivery
                                    fees.
                                </p>
                                <b>5. Reservation of Rights</b>
                                <p>
                                    Arihafoods Pvt. Ltd reserves the right to refuse
                                    service to anyone for any reason at any time. We also reserve
                                    the right to modify or discontinue any aspect of the services
                                    without notice at any time.
                                </p>
                                <b>6. Intellectual Property</b>
                                <p>
                                    All content included on the Arihafoods Pvt. Ltd
                                    website, including but not limited to text, graphics, logos,
                                    images, and software, is the property of Arihafoods Pvt. Ltd
                                    and is protected by copyright and other laws.
                                </p>
                                <b>7. Limitation of Liability</b>
                                <p>
                                    In no event shall Arihafoods Pvt. Ltd, its chefs,
                                    cooks, dishwashers, server, Manager or affiliates be liable for
                                    any direct, indirect, incidental, special, or consequential
                                    damages arising out of or in any way connected with the use of
                                    our services.
                                </p>
                                <b>8. Indemnification</b>
                                <p>
                                    You agree to indemnify and hold harmless Arihafoods Pvt. Ltd
                                    , its chefs, cooks, dishwashers, server, Manager or
                                    affiliates from and against any and all claims, damages,
                                    obligations, losses, liabilities, costs, or debt, and expenses
                                    arising from your use of the services or your violation of these
                                    Terms and Conditions.
                                </p>
                                <b>9. Governing Law</b>
                                <p>
                                    These Terms and Conditions shall be governed by and construed in
                                    accordance with the laws of India, without regard to its
                                    conflict of law provisions. The place of jurisdiction shall be
                                    exclusively in Chennai.
                                </p>
                                <b>10. Changes to Terms and Conditions</b>
                                <p>
                                    Arihafoods Pvt. Ltd reserves the right to update or
                                    modify these Terms and Conditions at any time without prior
                                    notice. Your continued use of the services after any changes to
                                    these terms shall constitute your acceptance of such changes.
                                </p>
                                <p>
                                    By accessing or using the services provided by Arihafoods Pvt. Ltd
                                    , you acknowledge that you have read,
                                    understood, and agree to be bound by these Terms and Conditions.
                                    If you do not agree to these terms, please do not access or use
                                    our services.
                                </p>
                                <p>
                                    For any questions or concerns regarding these Terms and
                                    Conditions, please contact us at <b>+91 98844 55605</b>.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
