export default function DisclaimerPage() {
    return (
        <>
            <div className="design-two disclaimer-content pt-4">
                <div className="page-header terms-page">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="page-header-box">
                                    <h1 className="text-anime">Disclaimer</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <ul className="terms-content">
                                    <li>
                                        <span>1.</span>
                                        The food items displayed on the Arihafoods Restaurant
                                        website are for illustrative purposes only. While we strive to
                                        accurately represent our menu items and their descriptions,
                                        actual dishes may vary in appearance and presentation.
                                    </li>
                                    <li>
                                        <span>2.</span>
                                        Allergen information provided on the website is based on
                                        standard recipes and ingredient lists. However, please be
                                        aware that our kitchen handles a wide variety of ingredients,
                                        and cross-contamination may occur. We cannot guarantee that
                                        any menu item will be completely free of allergens.
                                    </li>
                                    <li>
                                        <span>3.</span>
                                        Prices listed on the website are subject to change without
                                        notice. While we make every effort to ensure that pricing
                                        information is accurate, discrepancies may occasionally occur.
                                        Please confirm prices with the restaurant before placing your
                                        order.
                                    </li>
                                    <li>
                                        <span>4.</span>
                                        Promotional offers and discounts advertised on the website may
                                        be subject to specific terms and conditions. Please review any
                                        applicable terms before redeeming any offers.
                                    </li>
                                    <li>
                                        <span>5.</span>
                                        The information provided on our website, including but not
                                        limited to menu items, prices, and promotions, is subject to
                                        change at any time without prior notice. We recommend
                                        contacting the restaurant directly for the most up-to-date
                                        information.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
